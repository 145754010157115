<template>
  <div>
    <div id="section-content" class="container container-fluid">
      <div class="bar-back">
        <router-link to="/member/main"><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link>
      </div>
      <div
        class="p-2 w-100 bg-light main-content align-self-stretch mb-5"
        style="min-height: calc((100vh - 140px) - 50px)"
      >
        <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0">
          <div class="lotto-title d-flex flex-row align-items-end">
            <h4 class="mr-1"><i class="fas fa-tasks"></i> สถานะ ฝากเงิน</h4>
          </div>
        </div>
        <div class="bgwhitealpha text-white shadow-sm rounded xtarget col-lotto d-flex flex-column pb-0">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button class="btn btn-primary w-100" @click="getTranfer('1')">
              <i class="fas fa-wallet"></i> ทั้งหมด
            </button>
            <button class="btn btn-success w-100" @click="getTranfer('2')">
              <i class="fas fa-folder-plus"></i> ฝาก
            </button>
            <button class="btn btn-danger w-100" @click="getTranfer('3')">
              <i class="fas fa-folder-minus"></i> ถอน
            </button>
          </div>
        </div>
        <div class="mb-5">
          <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 col-lotto text-center">
            <span class="text-danger" v-if="checkData(listTransaction)">ไม่มีรายการ</span>
            <template v-else>
              <template v-for="(item, index) in listTransaction">
                <div class="row px-1 addbankstatus p-2 m-2" :key="index">
                  <div class="col-12 col-sm-12 px-0 table-dark rounded-top border border-secondary d-flex justify-content-between">
                      <div class="py-1 px-2 d-flex">
                          <div class="d-inline mr-2">
                              <img style="padding:2px;border-radius:2px;width:45px;"
                              :style="'background: ' + item.bankColor"
                              :src="imglink(item.bankBrand)">
                          </div>
                          <div class="d-inline">
                              <small class="badge badge-light text-dark text-uppercase">{{ item.bankBrand }}</small>
                              <br>
                              <small>{{ item.bankBrandName }}</small>
                          </div>
                      </div>
                      <div class="status confirm" style="min-width: 140px" :class="getBgColor(item.status)">
                        <small class="text-dark font-weight-bold">สถานะ</small><br>
                        <span><i :class="getIcon(item.status)"></i> {{ getStatus(item.status) }}</span>
                      </div>
                  </div>
                  <div class="col-12 py-1 px-2 text-dark table-secondary border border-top-0 border-bottom-0 border-secondary d-flex justify-content-between align-items-center">
                      <div class="d-flex align-items-center">
                        <span class="badge badge-light mr-1" :class="getStagementTextColor(item.stagement)" >
                        <i :class="getStagementIcon(item.stagement)"></i> {{ item.stagement }}
                        </span>
                          <h5 class="mb-0 d-inline thb">฿ {{ priceAll(item.amount) }}</h5></div>
                      <small><i class="fas fa-calendar-week"></i> {{ item.date }} {{ item.time }}</small>
                  </div>
                  <div class="col-12 rounded-bottom border border-top-0 border-secondary" style="overflow:hidden">
                      <div class="row">
                          <div class="col-6 col-sm-6 col-md-6 col-lg-4 py-1 px-2 d-flex flex-column flex-sm-column flex-md-row align-items-md-center">
                              <small class="\&quot;text-info\&quot; text-left">ชื่อบัญชี :</small>
                              <small class="text-left">&nbsp;{{ item.bankName }}</small>
                          </div>
                          <div class="col-6 col-sm-6 col-md-6 col-lg-4 py-1 px-2 d-flex flex-column flex-sm-column flex-md-row align-items-end align-items-sm-end align-items-md-center">
                              <small class="text-info">เลขที่บัญชี :</small>
                              <small class="">{{ item.bankNumber }} </small>
                          </div>
                          <!-- <div class="col-6 col-sm-6 col-md-6 col-lg-4 py-1 px-2 d-flex flex-column flex-sm-column flex-md-row align-items-end align-items-sm-end align-items-md-center">
                              <small class="text-info">เลขที่บัญชี :</small>
                              <small class="">{{ item.bankNumber }} </small>
                          </div> -->
                          <div class="col-12 col-sm-12 col-md-12 col-lg-4 py-1 px-2 bg-light border border-bottom-0 text-dark text-left">
                              <i class="fas fa-comment-alt"></i>
                            {{ getTextStatus(item.status) }}
                          </div>
                      </div>
                  </div>
                </div>
              </template>
            </template>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkTranferService } from '@/services'
import { mapActions } from 'vuex'
import { STATUS_SUCCESS } from '@/constants'
import { get, isEmpty } from '@/utils'
import baac from '@/assets/banks/baac.svg'
import bay from '@/assets/banks/bay.svg'
import bbl from '@/assets/banks/bbl.svg'
import bnp from '@/assets/banks/bnp.svg'
import boa from '@/assets/banks/boa.svg'
import cacib from '@/assets/banks/cacib.svg'
import cimb from '@/assets/banks/cimb.svg'
import citi from '@/assets/banks/citi.svg'
import db from '@/assets/banks/db.svg'
import ghb from '@/assets/banks/ghb.svg'
import gsb from '@/assets/banks/gsb.svg'
import hsbc from '@/assets/banks/hsbc.svg'
import ibank from '@/assets/banks/ibank.svg'
import icbc from '@/assets/banks/icbc.svg'
import jpm from '@/assets/banks/jpm.svg'
import kbank from '@/assets/banks/kbank.svg'
import kkp from '@/assets/banks/kkp.svg'
import ktb from '@/assets/banks/ktb.svg'
import lhb from '@/assets/banks/lhb.svg'
import mb from '@/assets/banks/mb.svg'
import mega from '@/assets/banks/mega.svg'
import mufg from '@/assets/banks/mufg.svg'
import rbs from '@/assets/banks/rbs.svg'
import sc from '@/assets/banks/sc.svg'
import scb from '@/assets/banks/scb.svg'
import smbc from '@/assets/banks/smbc.svg'
// import tcap from '@/assets/banks/tcap.svg'
import tisco from '@/assets/banks/tisco.svg'
import tmb from '@/assets/banks/tmb.svg'
import uob from '@/assets/banks/uob.svg'
import ttb from '@/assets/banks/ttb.svg'
import * as numeral from 'numeral'
export default {
  data () {
    return {
      listTransaction: [],
      imageBankMapping: {
        baac: baac,
        bay: bay,
        bbl: bbl,
        bnp: bnp,
        boa: boa,
        cacib: cacib,
        cimb: cimb,
        citi: citi,
        db: db,
        ghb: ghb,
        gsb: gsb,
        hsbc: hsbc,
        ibank: ibank,
        icbc: icbc,
        jpm: jpm,
        kbank: kbank,
        kkp: kkp,
        ktb: ktb,
        lhb: lhb,
        mb: mb,
        mega: mega,
        mufg: mufg,
        rbs: rbs,
        sc: sc,
        scb: scb,
        smbc: smbc,
        // tcap: tcap,
        tisco: tisco,
        tmb: tmb,
        uob: uob,
        ttb: ttb
      }
    }
  },
  async created () {
    await this.getTranfer('1')
  },
  methods: {
    ...mapActions(['setLoading', 'openInfoPopup']),
    getStatus (type) {
      let typeDesc = ''
      switch (Number(type)) {
        case 0:
          typeDesc = 'รอดำเนินการ'
          break
        case 1:
          typeDesc = 'ดำเนินการแล้ว'
          break
        default:
          typeDesc = 'ล้มเหลว'
          break
      }
      return typeDesc
    },
    getTextStatus (type) {
      let typeDesc = ''
      switch (Number(type)) {
        case 0:
          typeDesc = 'รอยอดจากธนาคาร'
          break
        case 1:
          typeDesc = 'อัพเดตเครดิตสำเร็จ'
          break
        default:
          typeDesc = 'ล้มเหลว'
          break
      }
      return typeDesc
    },
    checkData (value) {
      return isEmpty(value)
    },
    async getTranfer (option) {
      this.setLoading(true)
      window.scrollTo(0, 0)
      try {
        const resp = await checkTranferService()
        if (resp.status_code === STATUS_SUCCESS) {
          // console.log(option)
          switch (option) {
            case '2': {
              this.listTransaction = resp.data.filter(e => e.stagement === 'เติมเงิน')
              // this.listTransaction = resp.data.stagement === 'เติมเงิน' ? resp.data : []
              // console.log(this.listTransaction)
              break
            }
            case '3':
              this.listTransaction = resp.data.filter(e => e.stagement === 'ถอนเงิน')
              break
            default:
              this.listTransaction = resp.data
              break
          }
        }
      } catch (error) {}
      this.setLoading(false)
    },
    getBg (value) {
      let classDesc = ''
      switch (Number(value)) {
        case 0:
          classDesc = 'border-secondary table-dark'
          break
        case 1:
          classDesc = 'border-success table-success'
          break
        default:
          classDesc = 'border-danger table-danger'
          break
      }
      return classDesc
    },
    getColor (value) {
      let classDesc = ''
      switch (Number(value)) {
        case 0:
          classDesc = 'text-dark'
          break
        case 1:
          classDesc = 'text-success'
          break
        default:
          classDesc = 'text-danger'
          break
      }
      return classDesc
    },
    getBgColor (value) {
      let classDesc = ''
      switch (Number(value)) {
        case 0:
          classDesc = 'bg-warning'
          break
        case 1:
          classDesc = 'bg-success'
          break
        default:
          classDesc = 'bg-danger'
          break
      }
      return classDesc
    },
    getIcon (value) {
      let classDesc = ''
      switch (Number(value)) {
        case 0:
          classDesc = 'fas fa-spinner fa-spin'
          break
        case 1:
          classDesc = 'far fa-check-circle'
          break
        default:
          classDesc = 'fas fa-ban'
          break
      }
      return classDesc
    },
    imglink (short) {
      return get(this.imageBankMapping, [short])
    },
    getStagementTextColor (val) {
      if (val === 'เติมเงิน') {
        return 'text-success'
      } else {
        return 'text-danger'
      }
    },
    getStagementIcon (val) {
      if (val === 'เติมเงิน') {
        return 'fas fa-folder-plus'
      } else {
        return 'fas fa-folder-minus'
      }
    },
    priceAll (val) {
      return numeral(val).format('0,0.00')
    }
  }
}
</script>
